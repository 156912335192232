import React, { useEffect, useState } from 'react';
import { LogoutOutlined, UserOutlined, LoadingOutlined, MenuUnfoldOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Layout, Menu, Spin, Button, Typography, Row, Col, Avatar, Dropdown, Tooltip, Modal } from 'antd';
import { connect, useDispatch, useSelector } from 'react-redux';
import { States, State, RouteI, Response, Destination } from 'sigt';
import { Switch, Route, StaticContext, RouteComponentProps } from 'react-router';
import { Link, useLocation } from 'react-router-dom';
import { fetchAll, fetchInstitutions } from '../redux/actions/institutions';
import { getUserData } from '../redux/actions/auth';
import '../assets/css/layouts/DashboardLayout.css';
import '../assets/css/components/Welcome.css';
import { getRoutes } from '../routes';
import SubMenu from 'antd/lib/menu/SubMenu';
import UserFormGenerator from '../components/Forms/UserFormGenerator';
import OfficialFormGenerator from '../components/Forms/OfficialFormGenerator';
import { useWindowDimensions } from '../utils/hooks';
import { fetchBanks, fetchBankStatements } from '../redux/actions/bank';
// import { SocketService } from '../services/SocketService';
// import { SocketContext } from '../services/ServiceContext';
import PayProcedure from '../components/PayProcedure';
import RegisterForm from '../components/Forms/External/RegisterForm';
import OrdinanceForm from '../components/Forms/OrdinanceForm';
import { fetchParishes } from '../redux/actions/parish';
import Logo from '../assets/images/LOGO SUT/PNG/1.png';
import ColorBarH from '../assets/images/color-bar-h.png';
import { ReactComponent as Alcaldia } from '../assets/images/svg/11.svg';
import { setPrimaryColor } from '../redux/actions/themeColor';
import AffairsForm from '../components/Forms/AffairsForm';
import { SET_USER, AUTH_USER_LOGOUT } from '../redux/actions/actionsTypes';
import Notification from '../components/Notification/index';
import { fetchStats } from '../redux/actions/stats';
import UserPFForm from '../components/Forms/PassangerFee/UserPFForm';
import { fetchDestinationCosts } from '../redux/actions/cost';
import PayFine from '../components/PayFine';
import DeclareTaxes from '../components/Taxes/DeclareAnyTaxes';
import PayTaxes from '../components/Taxes/PayTaxes';
import { fetchRequests } from '../redux/actions/requests';
import PaySpecificTax from '../components/Taxes/PaySpecificTax';
import BankCalendarForm from '../components/Forms/BankCalendarForm';
// import TaxpayerRegister from "../components/Taxpayer/TaxpayerRegister";
import LinkAccount from '../components/Taxes/Linking/LinkAccount';
import BenefitTaxPayer from '../components/Taxes/BenefitTrib/BenefitTaxPayer';
import { fetchAES, fetchSM } from '../redux/actions/activities';
import Welcome from '../components/Welcome';
import AgreementTaxPayer from '../components/Taxes/BenefitTrib/AgreementTaxPayer';
import TaxpayerInfo from '../components/Taxpayer/TaxpayerInfo';
import { fetchBranches } from '../redux/actions/branches';
import Retentions from '../components/Taxes/Retentions';
import ExternalInfo from '../components/Taxpayer/ExternalInfo';
import LiqueurForm from '../components/Forms/LiqueurForm';
import InspectionForm from '../components/Forms/InspectionForm';
import { fetchBrands, fetchVehicles, getVehicleTypes } from '../redux/actions/vehicles';
import SupportForm from '../components/Forms/SupportForm';
import SupplementaryStatement from '../components/Taxes/SupplementaryStatement';
import SubstituteStatement from '../components/Taxes/SubstituteStatement';
import WalletDetails from '../components/Charges/WalletOverview';
import TableRetentions from '../components/Tables/TableRetentions';
import { fetchPetro, fetchPeso, fetchEuro, fetchDolar } from '../redux/actions/coins';
import Petro from '../components/Icons/Petro';
import Euro from '../components/Icons/Euro';
import Dolar from '../components/Icons/Dolar';
import petroPopup from '../assets/images/petroPopup.jpeg';
import UploadProperty from '../components/UploadProperty';
import CertificadeForm from '../components/Forms/CertificadeForm';
import Report from '../components/Report';
import RegisterVehicles from '../components/Taxes/Vehicles/RegisterVehicles';
import PayVehicleTax from '../components/Taxes/Vehicles/PayVehicleTax';
import RegisterOwners from '../components/Condominiums/RegisterOwners';
import Survey from '../components/Survey';
import { fetchCost } from '../redux/actions/cost';
import CheckVehicle from '../components/Taxes/Vehicles/CheckVehicle';
import { fetchDataIU } from '../redux/actions/estate';

const { Header, Content, Sider } = Layout;

const Dashboard: React.FC<DashboardProps & RouteComponentProps<{}, StaticContext, LocationState>> = ({
  auth,
  inst,
  history,
  location,
  fetchVehicles,
  fetchPetro,
  fetchPeso,
  fetchEuro,
  fetchDolar,
  fetchInstitutions,
  getVehicleTypes,
  getUserData,
  fetchBanks,
  fetchBankStatements,
  fetchParishes,
  thm,
  setPrimaryColor,
  fetchStats,
  stats,
  destinations,
  fetchDestinationCosts,
  fetchRequests,
  fetchAES,
  fetchSM,
  fetchBranches,
  fetchBrands,
  fetchAll,
  fetchCost,
  fetchDataIU,
}) => {
  const [dashboardRoutes, setDashboardRoutes] = useState<RouteI[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [showContent, setShowContent] = useState<boolean>(true);
  const [collapsed, setCollapsed] = useState<boolean>(true);
  const [isLogged, setIsLogged] = useState<boolean>(false);
  const [menuKey, setMenuKey] = useState<string[]>([]);
  const [modalPetro, setModalPetro] = useState<boolean>(false);
  const { width } = useWindowDimensions();
  const dispatch = useDispatch();
  const locationR = useLocation();
  const InstColors = {
    CBM: '#0aa679',
    PMM: '#0aa679',
    SAGAS: '#ec4e2b',
    DDLU: '#00a9e8',
    SEDETEMA: 'rgb(232,108,48)',
    IMTCUMA: 'rgb(65,52,123)',
    SEDEPAR: '#92CD20',
    HACIENDA: '#0091c8',
    IMAU: '#913E7B',
  };
  const localToken = localStorage.getItem('token');
  // const init = new SocketService();
  // const [socket, setSocket] = useState<SocketService>(init);
  const closeSidebarInLocation = ['cobranzasFiscalizacion', 'impuestos/retenciones', 'impuestos/declarar'];
  const imboxKey: string[] = [];
  const petro = useSelector((state: State) => state.coin.petro);
  const peso = useSelector((state: State) => state.coin.peso);
  const dolar = useSelector((state: State) => state.coin.dolar);
  const euro = useSelector((state: State) => state.coin.euro);

  const [userId, setUserId] = useState<number>(0);

  const formatCurrency = (number: number) => new Intl.NumberFormat('de-DE').format(number);

  useEffect(() => {
    if (auth.user && inst.institutions) {
      if (auth.user?.institucion?.id) fetchCost(auth.token, auth.user?.institucion?.id);
      setLoading(false);
      setDashboardRoutes(getRoutes(auth.user, inst.institutions || []));
      if (!stats && auth.user?.tipoUsuario === 2) fetchStats(auth.token || '');
      if (destinations.length === 0) fetchDestinationCosts(auth.token || '');
      if (auth.user?.tipoUsuario === 4) setModalPetro(true);
      if (auth.user?.tipoUsuario !== 4 && auth.user.institucion.id === 3) fetchDataIU(auth.token || '');
    }
    // eslint-disable-next-line
  }, [inst.institutions, auth.user, auth.token]);

  useEffect(() => {
    fetchParishes();
    fetchBrands();
    getVehicleTypes();
    fetchPetro();
    fetchPeso();
    fetchEuro();
    fetchDolar();
    if (auth.token && !inst.institutions) {
      fetchAll(auth.token);
      fetchInstitutions(auth.token);
      fetchBanks(auth.token);
      fetchBankStatements(auth.token);
      fetchRequests(auth.token);
      fetchAES(auth.token);
      fetchSM(auth.token);
    } else if (location.state && location.state.token && !inst.institutions) {
      localStorage.setItem('token', location.state.token);
      fetchAll(location.state.token);
      fetchInstitutions(location.state.token);
      fetchBanks(location.state.token);
      fetchBankStatements(location.state.token);
      fetchRequests(location.state.token);
      getUserData(location.state.token);
      fetchAES(location.state.token);
      fetchSM(location.state.token);
      // fetchBranches(location.state.token, userId);
    } else if (localToken && !inst.institutions) {
      fetchAll(localToken);
      getUserData(localToken);
      fetchRequests(localToken);
      fetchBanks(localToken);
      fetchBankStatements(localToken);
      fetchInstitutions(localToken);
      fetchAES(localToken);
      fetchSM(localToken);
      // fetchBranches(localToken, userId);
    }
    return () => {
      // if (socket && socket.disconnect) socket.disconnect();
    };
    // eslint-disable-next-line
  }, []);

  // useEffect(() => {
  //   if (auth.token) {
  //     _subscribeToEvents(auth.token);
  //   }
  //   // eslint-disable-next-line
  // }, [auth.token]);

  useEffect(() => {
    if (auth.user) setIsLogged(true);
    if (!auth.user && isLogged) history.push('/ingresar');
    const userId = auth.user?.institucion?.cargo?.id || 0;
    if (auth.token) fetchBranches(auth.token, userId);
    // eslint-disable-next-line
  }, [auth.user, auth.token]);

  useEffect(() => {
    if (auth.user?.tipoUsuario !== 4) {
      if (auth.user?.institucion?.nombreCorto) {
        setPrimaryColor(InstColors[auth.user?.institucion?.nombreCorto]);
      }
    }
    if (auth.user?.tipoUsuario === 4) {
      fetchVehicles();
    }
    // eslint-disable-next-line
  }, [auth.user]);

  useEffect(() => {
    if (
      (!auth.token && location.state && !location.state.token && !localToken) ||
      (!location.state && !auth.token && !localToken)
    ) {
      if (auth.user && auth.user?.tipoUsuario !== 4) {
        history.push('/ingresar/funcionarios');
      } else history.push('/ingresar');
    }
  }, [auth.token, auth.user, history, location.state, localToken]);

  useEffect(() => {
    if (width > 992 && !showContent) {
      setShowContent(true);
    }
    // eslint-disable-next-line
  }, [width]);

  useEffect(() => {
    if (closeSidebarInLocation.some((p) => locationR.pathname.includes(p))) {
      setCollapsed(true);
    }
    //eslint-disable-next-line
  }, [locationR]);

  // const _subscribeToEvents = async (token: string) => {
  // setSocket(socket.init(token));
  // SUBSCRIBE TO ALL EVENTS LIKE NOTIFICATIONS
  // };

  const renderContent = (routes: RouteI[]) => [
    ...routes.map(({ component: Component, path, exact }, index) => (
      <Route
        key={Array.isArray(path) ? path.join('') : path}
        path={!Array.isArray(path) ? `/dashboard/${path}` : path.map((p) => `/dashboard/${p}`)}
        exact={exact}
        component={Component}
      />
    )),
    ...routes
      .filter(({ subMenu, path }) => subMenu && path !== '/')
      .map(({ subMenu }) =>
        subMenu?.map(({ path, component: Component, exact }, index) => (
          <Route
            key={Array.isArray(path) ? path.join('') : path}
            path={!Array.isArray(path) ? `/dashboard/${path}` : path.map((p) => `/dashboard/${p}`)}
            exact={exact}
            component={Component}
          />
        ))
      ),
    <Route key='welcome' path='/dashboard' exact children={<Welcome />} />,
    <Route key='PF' path='/dashboard/tramite/4/17' exact children={<UserPFForm />} />,
    <Route key='formGen' path='/dashboard/tramite/:idInst/:idTramite/:idProceso?' children={<UserFormGenerator />} />,
    <Route key='licores' path='/dashboard/tramite/licores' children={<LiqueurForm />} />,
    <Route key='formGenOff' path='/dashboard/procesarTramite/:idTramite' children={<OfficialFormGenerator />} />,
    <Route key='inspectionForm' path='/dashboard/procesarTramiteInspeccion/:idTramite' children={<InspectionForm />} />,
    <Route key='payProc' path='/dashboard/pagarTramite/:idTramite' children={<PayProcedure />} />,
    <Route key='payFine' path='/dashboard/pagarMulta/:idMulta' children={<PayFine />} />,
    <Route key='consult' path='/dashboard/contrib/consultar' children={<ExternalInfo />} />,
    <Route key='declareTaxes' path='/dashboard/impuestos/declarar' children={<DeclareTaxes />} />,
    <Route key='payTaxes' path='/dashboard/impuestos/pagar' exact children={<PayTaxes />} />,
    <Route key='paySpecificTax' path='/dashboard/impuestos/pagar/:id' children={<PaySpecificTax />} />,
    <Route key='vehicles' path='/dashboard/impuestos/vehiculos' exact children={<RegisterVehicles />} />,
    <Route key='vehicles' path='/dashboard/impuestos/vehiculos/placa' exact children={<CheckVehicle />} />,
    <Route key='payVehicleTax' path='/dashboard/impuestos/vehiculos/pagar/:id' exact children={<PayVehicleTax />} />,
    <Route key='viewCondomium' path='/dashboard/condominios/ver/:id' exact children={<RegisterOwners />} />,
    // <Route key='registroContrib' path='/dashboard/impuestos/registro' children={<TaxpayerRegister />} />,
    <Route key='enlazarContrib' path='/dashboard/impuestos/enlazar' children={<LinkAccount />} />,
    <Route key='convenio' path='/dashboard/impuestos/convenios' children={<AgreementTaxPayer />} />,
    <Route key='convenio' path='/dashboard/inmuebles/documentos/cargar' children={<UploadProperty />} />,
    <Route key='convenio' path='/dashboard/inmuebles/documentos/ver/:id' children={<UploadProperty />} />,
    <Route key='retenciones' path='/dashboard/impuestos/retenciones' exact children={<Retentions />} />,
    <Route
      key='benefitTrib'
      path='/dashboard/benefitTributables/revision/:idTramite'
      exact
      children={() => <BenefitTaxPayer />}
    />,
    <Route key='contribInfo' path='/dashboard/contribuyente/:id' exact children={() => <TaxpayerInfo />} />,
    <Route key='contribInfo' path='/dashboard/correcciones/editar/contribuyente/:id' exact children={() => <TaxpayerInfo />} />,
    <Route key='comp' path='/dashboard/impuestos/complementaria' exact children={() => <SupplementaryStatement />} />,
    <Route key='cnsultRetenciones' path='/dashboard/impuestos/retenciones/consulta' exact children={() => <TableRetentions />} />,
    <Route key='sust' path='/dashboard/impuestos/sustitutiva' exact children={() => <SubstituteStatement />} />,
  ];

  const renderIcon = (Icon: React.ForwardRefExoticComponent<any> | undefined) => (Icon ? <Icon /> : null);

  const renderLinks = (routes: RouteI[], fatherShort: string | undefined = undefined) =>
    routes.map(({ subMenu, title, short, icon: Icon, path, component }, index) =>
      subMenu ? (
        <SubMenu
          key={`m-${title}`}
          title={
            short || Icon ? (
              <span>
                <b style={{ marginRight: 5 }}>{short || renderIcon(Icon)}</b>
                {title}
              </span>
            ) : (
              <span>{title}</span>
            )
          }
        >
          {subMenu ? renderLinks(subMenu, fatherShort ? fatherShort : short) : null}
        </SubMenu>
      ) : (
        <Menu.Item
          onClick={() => {
            if (fatherShort && InstColors[fatherShort]) setPrimaryColor(InstColors[fatherShort]);
          }}
          key={`i-${path}`}
        >
          <Link to={!Array.isArray(path) ? `/dashboard/${path}` : `/dashboard/${path[0]}`} className='nav-text'>
            {short || Icon ? (
              <span>
                <b style={{ marginRight: 5 }}>{short || renderIcon(Icon)}</b>
                {title}
              </span>
            ) : (
              <span>{title}</span>
            )}
          </Link>
          {path.includes('bandeja') ? imboxKey.push(`i-${path}`) : null}
        </Menu.Item>
      )
    );

  const onCollapse = () => {
    if (width < 576) setShowContent(!collapsed);
    setCollapsed(!collapsed);
  };

  const renderHeader = () => {
    const lg = width < 992;

    return (
      <Row align='middle' justify='space-between' style={{ flexFlow: 'row nowrap', height: '100%' }}>
        <Col style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} flex={lg ? 1 : undefined}>
          <div
            className='logo'
            style={lg ? { justifyContent: 'center', width: 'auto' } : { width: 290 }}
            onClick={() => history.push('/dashboard')}
          >
            <img src={Logo} alt='logo-sistema' style={{ cursor: 'pointer', objectFit: 'contain', width: '140px' }} />
          </div>
          {lg ? null : <span style={{ borderLeft: '1px solid #f0f0f0', margin: '16px 0' }} />}
        </Col>
        <Col flex={1}>
          <Row gutter={2} style={{ flexFlow: 'row nowrap' }} justify='end'>
            <Col className='header-nav'>
              <div className='wrapper-items'>
                <span style={{ padding: '0 12px', height: '100%' }}>
                  <Tooltip title={`Bs. ${formatCurrency(petro)}`}>
                    MMV
                    {/* <Petro style={{ width: 20, height: 20, padding: '0.1px 0px 3px' }} /> */}
                  </Tooltip>
                </span>
                <span style={{ padding: '0 12px', height: '100%' }}>
                  <Tooltip title={`Bs. ${formatCurrency(peso)}`}>COP</Tooltip>
                </span>
                <span style={{ padding: '0 12px', height: '100%' }}>
                  <Tooltip title={`Bs. ${formatCurrency(dolar)}`}>
                    <Dolar style={{ width: 20, height: 20, padding: '0.1px 0px 3px' }} />
                  </Tooltip>
                </span>
                <span style={{ padding: '0 12px', height: '100%' }}>
                  <Tooltip title={`Bs. ${formatCurrency(euro)}`}>
                    <Euro style={{ width: 20, height: 20, padding: '0.1px 0px 3px' }} />
                  </Tooltip>
                </span>
                {/* <span style={{ padding: '0 12px', height: '100%' }}>
                  <Tooltip title='Ayuda'>
                    <Button
                      target='_blank'
                      href='https://ayudasut.maracaibo.gob.ve'
                      type='link'
                      style={{ border: 'none' }}
                      icon={<QuestionCircleOutlined />}
                    />
                  </Tooltip>
                </span> */}
                {/* <Notification /> */}
                <Dropdown trigger={['click', 'hover']} overlay={userMenu}>
                  <span style={{ padding: '0 12px', height: '100%' }}>
                    <Avatar style={{ backgroundColor: thm.primaryColor }} size='small' icon={<UserOutlined />} />
                    {lg ? null : (
                      <Typography.Text style={{ marginLeft: 8, verticalAlign: 'middle' }} type='secondary'>
                        {auth.user?.nombreCompleto}
                      </Typography.Text>
                    )}
                  </span>
                </Dropdown>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };

  const signOut = () => {
    dispatch({ type: SET_USER, payload: { user: null, token: null } });
    dispatch({ type: AUTH_USER_LOGOUT });
    localStorage.clear();
  };

  const userMenu = (
    <Menu>
      {auth.user?.tipoUsuario !== 4 ? null : (
        <Menu.Item key='editProfile'>
          <Link to={`${locationR.pathname}/editarPerfil`}>
            <Button size='small' type='ghost' style={{ border: 'none' }} icon={<UserOutlined />}>
              Editar perfil
            </Button>
          </Link>
        </Menu.Item>
      )}
      <Menu.Divider />
      <Menu.Item key='logout'>
        <Button size='small' type='ghost' style={{ border: 'none' }} onClick={() => signOut()} icon={<LogoutOutlined />}>
          Cerrar sesion
        </Button>
      </Menu.Item>
    </Menu>
  );

  const zeroWithTriggerStyle = {
    backgroundColor: thm.primaryColor,
    boxShadow: '0px 0px 6px rgba(0,0,0,0.1)',
    display: width <= 380 && !collapsed ? 'none' : '',
  };
  return (
    // <SocketContext.Provider value={socket}>
    <Layout style={{ height: '100vh' }}>
      <Header id='header-dashboard' style={width < 768 ? { padding: '0 10px' } : {}}>
        {renderHeader()}
      </Header>
      <Layout>
        <Sider
          collapsible
          zeroWidthTriggerStyle={zeroWithTriggerStyle}
          style={{ backgroundColor: '#fff' }}
          breakpoint='lg'
          width={width > 380 ? 340 : width}
          collapsedWidth={0}
          onCollapse={onCollapse}
          collapsed={collapsed}
        >
          <Row justify='end' style={{ height: 36, padding: 10 }}>
            {width > 380 ? null : (
              <Button
                onClick={() => {
                  setShowContent(true);
                  setCollapsed(true);
                }}
                type='primary'
                icon={<MenuUnfoldOutlined />}
              />
            )}
          </Row>
          <Row className='menu-sidebar'>
            {loading && (
              <Spin
                className='custom-spinner'
                indicator={<LoadingOutlined style={{ fontSize: 50, color: '#00A175' }} spin />}
                size='large'
              />
            )}
            {!loading && (
              <Menu
                mode='inline'
                onSelect={({ selectedKeys }) => setMenuKey(selectedKeys)}
                selectedKeys={location.pathname.includes('bandeja') ? imboxKey : menuKey}
                onClick={
                  width < 992
                    ? () => {
                        setShowContent(true);
                        setCollapsed(true);
                      }
                    : () => {}
                }
              >
                {renderLinks(dashboardRoutes)}
              </Menu>
            )}
          </Row>
          <Row style={{ marginTop: 10 }} justify='center'>
            <div className='sidebard-alcaldia' style={{ width: '100%', paddingTop: '0.5rem', paddingLeft: '1.5rem' }}>
              <Alcaldia style={{ width: '60%' }} />
            </div>
          </Row>
        </Sider>
        <Content>
          <div style={{ padding: 20, height: '100%', overflowY: 'auto' }}>
            {showContent && (
              <Switch>
                {renderContent(dashboardRoutes)}
                {auth.user?.institucion && auth.user?.institucion?.id === 0 && auth.user?.tipoUsuario !== 2 && (
                  <Route path={`/dashboard/casosSociales/:id`} component={() => <AffairsForm editable={false} />} />
                )}
              </Switch>
            )}
            <Route path={`/dashboard/ordenanzas/crear`} component={() => <OrdinanceForm modal />} />
            <Route path={`/dashboard/ordenanzas/editar/:id`} component={() => <OrdinanceForm modal />} />
            <Route path={`/dashboard/funcionarios/crear`} component={() => <RegisterForm modal />} />
            <Route path={`/dashboard/funcionarios/editar/:id`} component={() => <RegisterForm modal />} />
            <Route path={`/dashboard/fechaBancaria/crear`} component={() => <BankCalendarForm modal />} />
            <Route path={`/dashboard/fechaBancaria/editar/:id`} component={() => <BankCalendarForm modal />} />
            <Route path={`/dashboard/soporte/crear`} component={() => <SupportForm />} />
            <Route path={`/dashboard/soporte/ver/ticket/:id`} component={() => <SupportForm />} />
            <Route path={`/dashboard/soporte/ticket/:id`} component={() => <SupportForm />} />
            <Route path={`/dashboard/cobranzasFiscalizacion/carteras/:id`} exact component={() => <WalletDetails />} />
            <Route path={`/dashboard/certificado/SM/generar`} exact component={() => <CertificadeForm />} />
            <Route path={`/dashboard/certificado/IU/generar`} exact component={() => <CertificadeForm />} />
            <Route path={`/dashboard/certificado/SOLA/generar`} exact component={() => <CertificadeForm />} />
            <Route path={`/dashboard/reportes/:type(\\d+)`} component={() => <Report />} />
            {locationR.pathname.endsWith('editarPerfil') ? <RegisterForm drawer /> : null}
            {/* <Modal onCancel={() => setModalPetro(false)} maskClosable={false} centered visible={modalPetro} footer={null} style={{ minHeight:'400px' }}><img style={{width:'100%', paddingTop:'20px'}} alt='petro' src={petroPopup} /></Modal> */}
            {/* <Survey /> */}
          </div>
        </Content>
      </Layout>
    </Layout>
    // </SocketContext.Provider>
  );
};

const mapStateToProps = (state: State) => ({
  auth: state.auth,
  inst: state.inst,
  thm: state.thm,
  stats: state.st.stats,
  destinations: state.cst.destinations,
});

export default connect(mapStateToProps, {
  fetchInstitutions,
  getUserData,
  fetchBanks,
  fetchBankStatements,
  fetchParishes,
  setPrimaryColor,
  fetchStats,
  fetchVehicles,
  getVehicleTypes,
  fetchDestinationCosts,
  fetchRequests,
  fetchAES,
  fetchSM,
  fetchBranches,
  fetchBrands,
  fetchPetro,
  fetchPeso,
  fetchEuro,
  fetchDolar,
  fetchAll,
  fetchCost,
  fetchDataIU,
})(Dashboard);

interface DashboardProps {
  auth: States.Auth;
  inst: States.Institutions;
  thm: States.ThemeColors;
  fetchInstitutions: (token: string) => Promise<void>;
  fetchParishes: () => Promise<void>;
  fetchBanks: (token: string) => Promise<void>;
  fetchBankStatements: (token: string) => Promise<void>;
  getUserData: (token: string) => Promise<Response>;
  setPrimaryColor: Function;
  fetchStats: (token: string) => Promise<void>;
  stats: States.Stats['stats'];
  destinations: Destination[];
  fetchDestinationCosts: (token: string) => Promise<void>;
  fetchRequests: (token: string) => Promise<void>;
  fetchAES: (token: string) => Promise<void>;
  fetchSM: (token: string) => Promise<void>;
  fetchBranches: (token: string, userId: number) => Promise<void>;
  fetchBrands: () => Promise<void>;
  fetchPetro: () => Promise<any>;
  fetchPeso: () => Promise<any>;
  fetchEuro: () => Promise<any>;
  fetchDolar: () => Promise<any>;
  fetchAll: (token: string) => Promise<void>;
  fetchVehicles: () => Promise<void>;
  getVehicleTypes: () => Promise<void>;
  fetchCost: Function;
  fetchDataIU: Function;
}

interface LocationState {
  token?: string;
}
