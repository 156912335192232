import React, { useState } from 'react';
import ValidatedList from './ValidatedList';
import { UploadOutlined } from '@ant-design/icons';
import { Card, Select, Upload, Button, Row, Col, message, Typography } from 'antd';
import handlingMessage from '../../utils/handlingMessage';
import { validatePayments } from '../../services/banks';
import moment from 'moment';
import XLSX from 'xlsx';
import { useWindowDimensions } from '../../utils/hooks';
import { State, States } from 'sigt';
import { connect, useSelector } from 'react-redux';
import { reportPaymentsValidated } from 'src/services/report';

const { Option } = Select;

const ValidatePayments: React.FC<ValidatePaymentsProps> = ({ thm, banks }) => {
  const [fileList, setFileList] = useState<any[]>([]);
  const [bank, setBank] = useState(1);
  const [listData, setListData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const { width } = useWindowDimensions();
  const auth = useSelector((state: State) => state.auth);

  const ExcelDateToMomentJS = (serial) => {
    // let days = Math.floor(serial);
    let hours = Math.floor((serial % 1) * 24);
    let minutes = Math.floor(((serial % 1) * 24 - hours) * 60);
    const full_date = new Date(Date.UTC(0, 0, serial, hours - 20, minutes));
    return moment(full_date).toISOString();
  };

  const handleUpload = async () => {
    const reader = new FileReader();
    let json: [] | any;
    let error: string | undefined;

    if (bank === 1) {
      reader.onload = function (event: any) {
        let fileContent: any;
        json = [];
        fileContent = event.target.result;
        //BOD
        try {
          if (fileList[0].type === 'application/json') {
            const enc = new TextDecoder('utf-8');
            json = JSON.parse(enc.decode(fileContent));
            //first Out : bank account data
            json.shift();
            if ('Referencia' in json[0] && 'Monto' in json[0] && 'Fecha' in json[0]) {
              json.forEach((e) => {
                e.Fecha = moment(e.Fecha, 'DD/MM/YYYY').toISOString();
              });
            } else {
              json = [];
              error = 'El archivo cargado no contiene los datos esperados!';
            }
          } else {
            let arr = fileContent.split('\n');
            arr = arr.map((e) => e.split(','));
            arr.forEach((row) => {
              if (row.length === 7) {
                json.push({
                  Fecha: moment(row[0], 'DD-M-YYYY').toISOString(),
                  Referencia: row[2],
                  Descripcion: row[3],
                  Monto: parseFloat(row[4]),
                });
              }
            });
          }
        } catch (err) {
          error = 'El archivo cargado no es el esperado o ocurrio un error leyendo el archivo.';
        }

        if (!error) {
          handlingMessage({
            action: () => validatePayments({ bank, data: json }),
            key: 'upload',
            loadingMessage: 'Subiendo archivos...',
            cb: (data) => {
              setListData([...listData, ...data.validatePayments.data]);
              setFileList([]);
            },
          });
        } else {
          message.error(error);
        }
      };
      if (fileList[0]?.type === 'application/json') {
        reader.readAsArrayBuffer(fileList[0]);
      } else {
        reader.readAsText(fileList[0]);
      }
    } else {
      reader.onload = function (event: any) {
        let buffer: any;
        buffer = event.target.result;
        let error: string | undefined;
        json = [];
        try {
          //raw is used to keep all data as string
          let data = XLSX.read(buffer, { type: 'buffer', raw: true });
          let arr: any[] = XLSX.utils.sheet_to_json(data.Sheets[data.SheetNames[0]], {
            header: 1,
          });

          //BANESCO
          if (bank === 2) {
            if (arr[0].includes('Referencia') && arr[0].includes('Monto') && arr[0].includes('Fecha')) {
              for (let i = 1; i < arr.length; i++) {
                if (!arr[i].includes('Referencia') && !arr[i].includes('Monto') && !arr[i].includes('Fecha')) {
                  json.push({
                    Fecha: ExcelDateToMomentJS(arr[i][0]), //date object
                    Referencia: arr[i][1],
                    Descripcion: arr[i][2],
                    Monto: arr[i][3],
                  });
                }
              }
            } else {
              error = 'El archivo cargado no contiene los datos esperados!';
            }
          }

          //BNC
          if (bank === 3) {
            if (arr[15][1].includes('Fecha') && arr[15][12].includes('Referencia') && arr[15][15].includes('Haber')) {
              for (let i = 16; i < arr.length; i++) {
                json.push({
                  Fecha: ExcelDateToMomentJS(arr[i][1]), //date object
                  Referencia: arr[i][12],
                  Monto: arr[i][16],
                });
              }
            } else {
              error = 'El archivo cargado no contiene los datos esperados!';
            }
          }

          //BANCO DEL TESORO
          if (bank === 14) {
            if (arr[0][0].includes('Fecha') && arr[0][2].includes('Referencia') && arr[0][4].includes('Abono')) {
              for (let i = 1; i < arr.length; i++) {
                if (arr[i][2] !== 0) {
                  json.push({
                    Fecha: ExcelDateToMomentJS(arr[i][0]), //date object
                    Referencia: `${arr[i][2]}`,
                    Monto: arr[i][4],
                  });
                }
              }
            } else {
              error = 'El archivo cargado no contiene los datos esperados!';
            }
          }

          //BANCO PLAZA
          if (bank === 22) {
            if (arr[1][0].includes('Fecha') && arr[1][1].includes('Referencia') && arr[1][4].includes('Abono')) {
              for (let i = 2; i < arr.length; i++) {
                const Monto = parseFloat(arr[i][4].replace(/\./g, '').replace(/,/, '.'));
                json.push({
                  Fecha: moment(arr[i][0], 'DD/MM/YYYY').toISOString(), //date object
                  Referencia: arr[i][1],
                  Monto,
                });
              }
            } else {
              error = 'El archivo cargado no contiene los datos esperados!';
            }
          }

          //BANCRECER
          if (bank === 25) {
            if (arr[0][0].includes('FECHA') && arr[0][1].includes('REFERENCIA') && arr[0][4].includes('CREDITOS')) {
              for (let i = 2; i < arr.length; i++) {
                if (!!arr[i][4]) {
                  json.push({
                    Fecha: moment(arr[i][0], 'YYYY-MM-DD').toISOString(), //date object
                    Referencia: `${arr[i][1]}`,
                    Monto: parseFloat(arr[i][4]?.replace(/\./g, '')?.replace(/,/, '.')),
                  });
                }
              }
            } else {
              error = 'El archivo cargado no contiene los datos esperados!';
            }
          }

          // BANCAMIGA
          if (bank === 5) {
            const row = 5;
            if (arr[row][2] === 'Referencia' && arr[row][5] === 'Crédito' && arr[row][1] === 'Fecha') {
              for (let i = 6; i < arr.length; i++) {
                // NOTA: Se debe ignora la fila con débito
                if (arr[i].length > 2 && !isNaN(arr[i][5]) && arr[i][4] === 0) {
                  json.push({
                    Fecha: ExcelDateToMomentJS(arr[i][1]), //date object
                    Referencia: `${arr[i][2]}`.replace(/[^\d]/g, '').trim(),
                    Monto: arr[i][5] * 1, // Lo paso a positivo para evitar cualquier monto negativo
                  });
                }
              }
            } else {
              error = 'El archivo cargado no contiene los datos esperados! validatePayments';
            }
          }

          // MERCANTIL
          if (bank === 19) {
            if (arr[0][0] === 'REFERENCIA' && arr[0][2] === 'MONTO' && arr[0][1] === 'FECHA') {
              for (let i = 1; i < arr.length; i++) {
                if (arr[i].length > 2 && !isNaN(arr[i][2])) {
                  json.push({
                    Fecha: ExcelDateToMomentJS(arr[i][1]), //date object
                    Referencia: `${arr[i][0]}`,
                    Monto: arr[i][2] * 1, // Lo paso a positivo para evitar cualquier monto negativo
                  });
                }
              }
            } else {
              error = 'El archivo cargado no contiene los datos esperados! validatePayments';
            }
          }

          // PROVINCIAL
          if (bank === 23) {
            const row = 12;
            if (
              arr[row][0]?.trim()?.includes('F. Operación') &&
              arr[row][3]?.trim()?.includes('Nº. Doc.') &&
              arr[row][5]?.trim()?.includes('Importe')
            ) {
              for (let i = 15; i < arr.length; i++) {
                if (arr[i].length > 2 && !isNaN(arr[i][2])) {
                  json.push({
                    Fecha: ExcelDateToMomentJS(arr[i][0]), //date object
                    Referencia: `${arr[i][3]}`.replace(/[^\d]/g, '').trim(),
                    Monto: arr[i][5] * 1, // Lo paso a positivo para evitar cualquier monto negativo
                  });
                }
              }
            } else {
              error = 'El archivo cargado no contiene los datos esperados!';
            }
          }

          // VENEZUELA
          if (bank === 11) {
            if (arr[0][0] === 'fecha' && arr[0][1] === 'referencia' && arr[0][4] === 'monto') {
              for (let i = 1; i < arr.length; i++) {
                if (arr[i][5].toUpperCase().includes('NOTA DE CRÉDITO')) {
                  json.push({
                    Fecha: moment(arr[i][0], 'DD/MM/YYYY - hh:mm').startOf('day').toISOString(), //date object
                    Referencia: arr[i][1].trim(),
                    Monto: parseFloat(arr[i][4].replace('.', '').replace(',', '.')),
                  });
                }
              }
            } else {
              error = 'El archivo cargado no contiene los datos esperados!';
            }
          }

          // VENEZUELA DIA ACTUAL
          if (bank === 11111) {
            if (arr[0][0] === 'fecha' && arr[0][1] === 'referencia' && arr[0][4] === 'credito') {
              for (let i = 1; i < arr.length; i++) {
                if (arr[i][7].toUpperCase().includes('NOTA DE CRÉDITO')) {
                  json.push({
                    Fecha: moment(arr[i][0], 'DD/MM/YYYY - hh:mm').startOf('day').toISOString(), //date object
                    Referencia: arr[i][1].trim(),
                    Monto: parseFloat(arr[i][4].replace('.', '').replace(',', '.')),
                  });
                }
              }
            } else {
              error = 'El archivo cargado no contiene los datos esperados!';
            }
          }

          //BANCARIBE
          if (bank === 12) {
            for (let i = 1; i < arr.length; i++) {
              if (typeof arr[i][0] === 'number' && typeof arr[i][4] === 'number') {
                json.push({
                  Fecha: ExcelDateToMomentJS(arr[i][0]), //date object
                  Referencia: `${arr[i][1]}`,
                  Monto: arr[i][4],
                });
              }
            }
          }
        } catch (err) {
          console.log(err);
          json = [];
          error = 'El archivo cargado no es el esperado.';
        }

        if (!error) {
          handlingMessage({
            action: () => validatePayments({ bank, data: json }),
            key: 'upload',
            loadingMessage: 'Subiendo archivos...',
            cb: (data) => {
              setListData([...listData, ...data.validatePayments.data]);
              setFileList([]);
            },
          });
        } else {
          message.error(error);
        }
      };
      reader.readAsArrayBuffer(fileList[0]);
    }
  };

  const excelTypes = [
    'application/vnd.ms-excel',
    'application/excel',
    'application/vnd.ms-excel',
    'application/x-excel',
    'application/x-msexcel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ];

  const uploadProps = {
    onRemove: () => {
      setFileList([]);
    },
    beforeUpload: (file: Blob) => {
      const BODFileTypes = ['application/vnd.ms-excel', 'text/csv', 'application/json'];
      if (bank !== 1 && !excelTypes.includes(file.type)) {
        message.error('Solo son aceptados los archivos tipo XLS o XLSX');
      } else if (bank === 1 && !BODFileTypes.includes(file.type)) {
        message.error('Solo son aceptados los archivos tipo csv o json');
      }
      setFileList([file]);
      return false;
    },
    accept: bank === 1 ? '.csv, .json' : '.xls,.xlsx',
    fileList,
  };

  const handleReportPayValidated = async () => {
    try {
      setLoading(true);
      const data = await reportPaymentsValidated({ data: listData }, auth.token);
      const win = window.open(data, '_blank');
      win?.focus();
    } catch (error) {
      message.error('Error al descargar el archivo');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card
      style={{ height: '100%' }}
      title='Validación de Pagos'
      bodyStyle={{ height: 'calc(100% - 88px)', overflowY: 'scroll', overflowX: 'hidden' }}
      headStyle={{ height: 64, backgroundColor: thm.primaryColor, padding: width < 992 ? '0 10px' : '0 20px', color: 'white' }}
    >
      <Row gutter={[8, 16]}>
        <Col xs={24} sm={12}>
          <Select
            placeholder='Seleccione banco'
            onChange={(bank: number) => {
              setBank(bank);
              setFileList([]);
            }}
            defaultValue={1}
            style={{ width: '100%' }}
          >
            {[
              ...banks,
              { id: 11111, nombre: 'Banco De Venezuela S.A.C.A. Banco Universal Dia Actual', validador: true, codigo: '0102' },
            ]
              .filter((b) => b.validador)
              .map((b) => (
                <Option key={b.id} value={b.id}>
                  {b.nombre}
                </Option>
              ))}
          </Select>
        </Col>
        <Col xs={24} sm={12} md={4}>
          <Button style={{ width: '100%' }} type='primary' onClick={handleUpload} disabled={typeof fileList[0] === 'undefined'}>
            Validar Pagos
          </Button>
        </Col>
      </Row>
      <Row gutter={[8, 40]}>
        <Col xs={24} md={12}>
          <Upload {...uploadProps}>
            <Button disabled={typeof fileList[0] !== 'undefined'}>
              <UploadOutlined /> Subir Archivo
            </Button>
          </Upload>
        </Col>
      </Row>
      <Row gutter={[8, 24]}>
        <Col>
          <Typography.Title level={4}>Pagos validados</Typography.Title>
        </Col>
        <Col>
          <Button disabled={!listData} loading={loading} onClick={() => handleReportPayValidated()}>
            Descargar soporte
          </Button>
        </Col>
        <Col span={24}>
          <ValidatedList listData={listData} />
        </Col>
      </Row>
    </Card>
  );
};

const mapStateToProps = (state: State) => ({ thm: state.thm, banks: state.bk.banks });

export default connect(mapStateToProps)(ValidatePayments);

interface ValidatePaymentsProps {
  thm: States.ThemeColors;
  banks: States.Banks['banks'];
}
