import { RocketOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Card, Col, Form, Input, message, Row, Select, Table, Tag, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import Axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Procedure, State } from 'sigt';
import { useWindowDimensions } from 'src/utils/hooks';
import { Utils } from 'src/utils/validators';
import { StatusConfig } from '../Tables/TableProcedure';
import { useHistory } from 'react-router';
const server = process.env.REACT_APP_SERVER_URL;

type TConsultType = 'NO_SELECTED' | 'CODIGO_TRAMITE' | 'NUMERO_SOLICITUD';

export default function ConsultProcedures() {
  const history = useHistory();
  const thm = useSelector((state: State) => state.thm);
  // const inst = useSelector((state: State) => state.inst.institutions);
  const auth = useSelector((state: State) => state.auth);
  const { width } = useWindowDimensions();
  const [form] = Form.useForm();
  const [searching, setSearching] = useState(false);
  const [data, setData] = useState([]);
  const [consultType, setConsultType] = useState<TConsultType>('NO_SELECTED');
  // const userInst = inst?.find((i) => i?.id === (auth?.user?.institucion?.id ?? 0));
  // const userPermisoTramite: number[] = auth?.user?.permisos ?? [0];

  const columns: ColumnsType<Procedure> = [
    {
      title: 'Codigo',
      dataIndex: 'codigoTramite',
      align: 'center',
    },
    {
      title: 'Trámite',
      dataIndex: 'nombreTramiteCorto',
      align: 'center',
      render: (text, record) => <Tooltip title={record.nombreTramiteLargo}>{text}</Tooltip>,
    },
    {
      title: 'Fecha',
      dataIndex: 'fechaCreacion',
      align: 'center',
      render: (text, record) => {
        let time = '';
        let now = new Date();

        return time !== '' ? (
          <Tooltip
            title={`Tiempo desde el ultimo cambio de estado: 
            ${moment(now).fromNow()}`}
          >
            {moment(text).format('DD/MM/YYYY')}
          </Tooltip>
        ) : (
          <Tooltip title='Fecha de Creacion'>{moment(text).format('DD/MM/YYYY')}</Tooltip>
        );
      },
    },
    {
      title: 'Estado',
      dataIndex: 'estado',
      align: 'center',
      render: (value, record) => (
        <Tag style={{ width: '100%', textAlign: 'center' }} color={StatusConfig[value]?.color}>
          {StatusConfig[value]?.name}
        </Tag>
      ),
    },
    {
      title: '',
      align: 'center',
      width: 50,
      render: (record) => {
        if (obtenerEstadoTramite(auth?.user?.institucion?.cargo?.id, record?.idTipoTramite) !== record.estado) return null;
        return (
          <Button
            shape='round'
            type='danger'
            icon={<RocketOutlined />}
            onClick={() => {
              history.push(`/dashboard/procesarTramite/${record.id}`, { procedure: record });
            }}
          >
            {width > 440 ? 'Procesar' : null}
          </Button>
        );
      },
    },
  ];

  function obtenerEstadoTramite(idCargo, idTipoTramite) {
    let estado;

    switch (idCargo) {
      case 111:
        estado = 'enproceso_recepcion_taquilla';
        break;
      case 119:
        estado = [120, 121].includes(idTipoTramite) ? 'enproceso_recepcion_taquilla' : null;
        break;
      case 112:
        estado = 'enproceso_archivo';
        break;
      case 113:
        estado = 'enrevision_coordinador';
        break;
      case 114:
        estado = 'inspeccion_topografo';
        break;
      case 116:
        estado = 'enrevision_transcripcion';
        break;
      case 117:
        estado = 'enrevision_geografo';
        break;
      case 118:
        estado = estado === 'enrevision_director' || estado === 'finalizado' ? estado : null;
        break;
      case 120:
        estado = 'enrevision_area_legal';
        break;
      case 121:
        estado = [120, 121].includes(idTipoTramite) ? 'enrevision_sindico' : null;
        break;
      default:
        estado = null;
    }

    return estado;
  }

  const arrayConsultType = [
    {
      label: 'Codigo del tramite',
      value: 'CODIGO_TRAMITE',
    },
    {
      label: 'Numero de solicitud',
      value: 'NUMERO_SOLICITUD',
    },
  ];

  const search = async () => {
    const values = await form.validateFields();
    try {
      setSearching(true);

      const response = await Axios.post(`${server}/procedures/search/procedure`, values, {
        headers: { Authorization: `Bearer ${auth.token}` },
      });
      console.log(response);
      if (response.data.status) {
        setData(response.data.procedures);
      }
    } catch (e) {
      message.error(e.response?.data?.message || 'Error al buscar tramite');
    } finally {
      setSearching(false);
    }
  };

  return (
    <Card
      style={{ height: '100%' }}
      title='Consultar Tramite'
      bodyStyle={{ height: 'calc(100% - 88px)', overflowY: 'scroll', overflowX: 'hidden', padding: '10px 40px' }}
      headStyle={{ height: 64, backgroundColor: thm.primaryColor, padding: width < 992 ? '0 10px' : '0 20px', color: 'white' }}
    >
      <Row>
        <Col span={24}>
          <Form form={form} layout='vertical'>
            <Row justify='start' align='middle' gutter={[24, 24]}>
              <Col xs={24} xl={10}>
                <Form.Item label='Tipo de consulta' name={'tipoConsulta'} rules={[{ required: true }]}>
                  <Select
                    placeholder='Tipo de consulta'
                    style={{ width: '100%', marginTop: 8 }}
                    onChange={(e: TConsultType) => setConsultType(e)}
                  >
                    {arrayConsultType.map((c) => (
                      <Select.Option key={c.value} value={c.value}>
                        <span style={{ whiteSpace: 'break-spaces' }}>{c.label}</span>
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              {consultType !== 'NO_SELECTED' && (
                <Col xs={24} xl={7}>
                  <Button
                    style={{ marginTop: 16 }}
                    type='primary'
                    htmlType='submit'
                    onClick={() => search()}
                    icon={<SearchOutlined />}
                  >
                    Buscar
                  </Button>
                </Col>
              )}
            </Row>
            <Row justify='start' align='middle' gutter={[24, 24]}>
              {/* <Col xs={24} xl={7}>
                <Form.Item
                  label='Tipo de tramite'
                  name={'idTipoTramite'}
                  rules={[{ required: true, message: 'Seleccione un tipo de tramite' }]}
                >
                  <Select placeholder='Tipo de tramite' style={{ width: '100%' }}>
                    {userInst?.tramitesDisponibles
                      ?.filter((f) => userPermisoTramite?.includes(f.id))
                      ?.map((c, i) => (
                        <Select.Option key={i} value={c.id}>
                          <span style={{ whiteSpace: 'break-spaces' }}>{c.titulo}</span>
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col> */}
              {consultType === 'CODIGO_TRAMITE' && (
                <Col xs={24} xl={10}>
                  <Form.Item
                    label='Código del tramite'
                    name='codigoTramite'
                    rules={[{ required: true, message: 'Ingrese el código del tramite' }]}
                    normalize={(v) => v?.trim()}
                  >
                    <Input placeholder='Código del tramite' />
                  </Form.Item>
                </Col>
              )}
              {consultType === 'NUMERO_SOLICITUD' && (
                <Col xs={24} xl={10}>
                  <Form.Item
                    label='Numero de solicitud'
                    name='numeroSolicitud'
                    rules={[{ required: true, message: 'Ingrese el número de solicitud' }]}
                    normalize={Utils.normalize.isNumber}
                  >
                    <Input placeholder='Numero de solicitud' />
                  </Form.Item>
                </Col>
              )}
            </Row>
          </Form>
        </Col>
        <Col span={24}>
          <Table columns={columns} dataSource={data} bordered loading={searching} />
        </Col>
      </Row>
    </Card>
  );
}
